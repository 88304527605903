import React, { useState, useEffect } from "react";
import { Card, Container, Row, Col, Form, Button, Tab, Tabs } from 'react-bootstrap'
import DP from 'assets/images/dp.jpg'
import { FaPhoneAlt } from 'react-icons/fa';
import { AiOutlineFileSearch } from 'react-icons/ai'
import { Modal } from 'react-bootstrap';
import { GoLocation } from 'react-icons/go'
import { CgGenderMale,CgGenderFemale } from 'react-icons/cg'
import { AiOutlineClockCircle, AiOutlineCheckCircle, AiOutlineCloseCircle, AiOutlineArrowRight } from 'react-icons/ai'
import ReactStars from "react-rating-stars-component";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { API } from 'Services'
import Global from 'store/Global';
import { Ddux } from 'ddux';

const ManageUsers = () => {
  const [userList, setUserList] = useState([])
  const [selectedUserIndex, setselectedUserIndex] = useState(null)
  const [documentModal, setDocumentModal] = useState(false)

    useEffect(() => {
        loadUserList()
      },[]);
    
    const loadUserList=async()=>{
        Ddux.update('loading', 1)
        let data = await API.listAllUser()
        if(data.success)
        {
            setUserList(data.data)
            console.log(data.data)
            Ddux.update('loading', 0)
        }
        else {
            alert(data.error)
            Ddux.update('loading', 0)
            // ShowToast({message: 'System Error!Refresh The Page!',type: 'error'});
        }
    }

    const changeStatus=async(id,status)=>{
        Ddux.update('loading', 1)
        let data = await API.changeUserStatus(id,status)
        if(data.success)
        {
            loadUserList()
            console.log(data.data)
            Ddux.update('loading', 0)
        }
        else {
            alert(data.error)
            Ddux.update('loading', 0)
            // ShowToast({message: 'System Error!Refresh The Page!',type: 'error'});
        }
    }

    return (
        <div id="screen__manageUser">

            <h4 className='tab-title'>Manage Users</h4>
            <div className="grid-widget">
                <div className="grid-widget grid-container">
                    {
                        userList && userList.map((item, i) => (
                            <div className="grid-container user-container" key={i} >
                                <div className={`user-wrapper ${(userList[selectedUserIndex] && userList[selectedUserIndex]._id == item._id) ? 'user-wrapper-active' : ''}`} onClick={async() => {
                                    await setselectedUserIndex(null);setselectedUserIndex(i);
                                }}>
                                    <Row className="align-items-center">
                                        <img alt="Dp" src={item.profile_picture? Global.apiUrl+item.profile_picture : require('assets/images/usericon.png')} style={{ borderRadius: 100, height: 50, width: 50, marginLeft: 5, marginTop: 5 }} />
                                        <div className="cardTitle">
                                            <p>{item.name}</p>
                                            <div className="service-type">{ item.provider.service.toLowerCase() || 'consumer' }</div>
                                        </div>
                                    </Row>
                                    <div className="phone">
                                        <div className="cardDetail"><FaPhoneAlt color='#02aab0' style={{ marginRight: "9px" }} />{item.mobile}</div>
                                        <div className="cardStatus">
                                            {
                                                (item.status === "pending") ? <AiOutlineClockCircle size={15} color='#79BEFF' /> : item.status === "approved" ? <AiOutlineCheckCircle size={15} color='#a8ff78' /> : <AiOutlineCloseCircle size={15} color='rgb(255, 0, 77)' />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className={`drawer-container  ${userList[selectedUserIndex] ? 'drawer-container-open' : ''}`}>

                    <div className={`drawer-content ${userList[selectedUserIndex] ? 'drawer-content-open' : ''}`}>
                        <DrawerHeader setselectedUserIndex={setselectedUserIndex} userDetails={userList[selectedUserIndex]} />
                        <DrawerTabs userDetails={userList[selectedUserIndex]} />
                        {userList[selectedUserIndex] &&
                            <div className="button-container">
                                {(userList[selectedUserIndex].status == 'suspended' || userList[selectedUserIndex].status == 'pending') && <div className="approve-button" onClick={()=>{changeStatus(userList[selectedUserIndex]._id,'approved')}}> Approve </div>}
                                {userList[selectedUserIndex].status == 'approved' && <div className="suspend-button" onClick={()=>{changeStatus(userList[selectedUserIndex]._id,'suspended')}}> Suspend </div>}
                                {/* <div className="verify-button" onClick={()=>{}}> Verify </div> */}
                            </div>
                        }
                        {userList[selectedUserIndex] && userList[selectedUserIndex].provider.verification_document &&
                            <div className="document" onClick={()=>setDocumentModal(true)}>
                                <img style={{height: 40,width: 40}} src={require('assets/images/document.png')}/>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <ViewDocument documentModal={documentModal} setDocumentModal={setDocumentModal} userDetails={userList[selectedUserIndex]}/>
        </div>
    )
}

const DrawerHeader = ({ setselectedUserIndex, userDetails }) => {

    return (
        <div className='drawer-header-container'>
            <AiOutlineArrowRight style={{ color: '#B3B3B4', cursor: 'pointer', fontSize: 20, marginTop: 10, marginLeft: 10 }} onClick={() => {
                setselectedUserIndex(null)
            }}></AiOutlineArrowRight>
            {
                userDetails &&
                <div className='header-content'>
                    <img alt="Dp" src={userDetails.profile_picture? Global.apiUrl+userDetails.profile_picture : require('assets/images/usericon.png')} style={{ borderRadius: 100, height: 50, width: 50, marginLeft: 5, marginTop: 5 }} />
                    <p className="username">{userDetails.name}{userDetails.gender==='male'?<CgGenderMale />:<CgGenderFemale />}</p>
                    <div className="location"><GoLocation style={{ marginLeft: "5px" }} /> {userDetails.address}</div><br />
                    <div className='margin-auto inline-block'>
                        <ReactStars
                            count={5}
                            size={24}
                            edit={false}
                            isHalf={true}
                            value={userDetails.average_rating || 0}
                            emptyIcon={<i className="far fa-star"></i>}
                            halfIcon={<i className="fa fa-star-half-alt"></i>}
                            fullIcon={<i className="fa fa-star"></i>}
                            activeColor="#00cdac"
                        />
                    </div>
                </div>

            }
        </div>
    )
}

const DrawerTabs = ({ userDetails }) => {

    const calCompleteTask=(list)=>{
        let count = 0;
        for(var i=0;i<list.length;i++)
            if(list[i].status==='Completed') count++;
        return count;
    }
    const dateconvert=(iDate)=>{
        var a = new Date(iDate);
        return ("0"+a.getDate()).slice(-2) + "-" + ("0"+(a.getMonth()+1)).slice(-2)  + "-" + a.getFullYear();
    }

    return (
        <div className="tabs-container">
        {
            userDetails &&
            <Tabs defaultActiveKey="Provider" id="uncontrolled-tab-example" >
                <Tab eventKey="Provider" title="Provider" className="tabs-container-style">
                    <p>{userDetails.provider.description || ' - - - '}</p>
                    <h6>Completed Task : {calCompleteTask(userDetails.provider_task)}</h6>
                    <h6>Last Reviews :</h6>
                        {userDetails.reviews[0] &&
                            <div className='margin-bottom-10'>
                                {userDetails.reviews[0].username}
                                <p className='font-14 review-text'>{userDetails.reviews[0].feedback}</p>
                                <ReactStars
                                    count={5}
                                    size={15}
                                    edit={false}
                                    isHalf={true}
                                    value={userDetails.reviews[0].rating}
                                    emptyIcon={<i className="far fa-star"></i>}
                                    halfIcon={<i className="fa fa-star-half-alt"></i>}
                                    fullIcon={<i className="fa fa-star"></i>}
                                    activeColor="#00cdac"
                                />
                            </div>
                        }
                        {userDetails.reviews[1] &&
                            <div className='margin-bottom-10'>
                                {userDetails.reviews[1].username}
                                <p className='font-14 review-text'>{userDetails.reviews[1].feedback}</p>
                                <ReactStars
                                    count={5}
                                    size={15}
                                    edit={false}
                                    isHalf={true}
                                    value={userDetails.reviews[1].rating}
                                    emptyIcon={<i className="far fa-star"></i>}
                                    halfIcon={<i className="fa fa-star-half-alt"></i>}
                                    fullIcon={<i className="fa fa-star"></i>}
                                    activeColor="#00cdac"
                                />
                            </div>
                        }
                    <h6>Latest Task :</h6>
                    {
                        userDetails.provider_task && userDetails.provider_task.map((item, i) => (
                            <p>{dateconvert(item.createdAt)} - {item.title.length>20?item.title.substring(1,20)+'...':item.title} - ({item.cost.total}$)</p> 
                        ))
                    }
                    <br /><br />
                </Tab>

                <Tab eventKey="Consumer" title="Consumer" className="tabs-container-style">
                    <h6>Completed Task : {calCompleteTask(userDetails.consumer_task)}</h6>
                    <h6>Latest Task :</h6>
                    {
                        userDetails.consumer_task && userDetails.consumer_task.map((item, i) => (
                            <p>{dateconvert(item.createdAt)} - {item.title.length>20?item.title.substring(0,20)+'...':item.title} - ({item.cost.total}$)</p> 
                        ))
                    }
                    <br /><br />
                </Tab>

            </Tabs>
        }
        </div>
    )
}

const ViewDocument = ({documentModal,setDocumentModal,userDetails})=>{
    // console.log(Global.siteUrl+userDetails.provider.verification_document)
    return(
        <Modal
            show={documentModal}
            onHide={()=>setDocumentModal(false)}
        >
            <Modal.Body>
                {
                    userDetails && userDetails.provider.verification_document &&
                    <DocViewer
                        pluginRenderers={DocViewerRenderers}
                        documents={[{uri: 'https://cors-anywhere.herokuapp.com/'+Global.siteUrl+userDetails.provider.verification_document}]}
                    />
                }
            </Modal.Body>
        </Modal>
    )
}

export { ManageUsers }
