import React from 'react';
import {FaBars} from "react-icons/fa";
import { AiOutlineSetting,AiOutlineLogout,AiOutlineBell } from 'react-icons/ai'
import { Navbar,Dropdown } from 'react-bootstrap';
import history from '../History';

const Header = ({expanded=false, toggle=()=>null}) => {

    const logout = () =>{
      localStorage.clear();
      history.push("/");
    }

    return ( 
          <Navbar expand="md" className={`navbar ${(expanded)?'navbar-expanded':''}`}>
            <div className={`bars ${(expanded)?'hide':''}`} onClick={()=>toggle()}> <FaBars size={30} /> </div>
            <Navbar.Brand href="/" className="brand-wrapper">
              <img src={require('assets/images/logo.png')} className="brand-logo" alt="logo" />
            </Navbar.Brand>
            <div className="my-account">
              <Dropdown>
                <Dropdown.Toggle style={{backgroundColor: 'transparent',borderColor: 'transparent',boxShadow: 'none'}}>
                  My Account
                </Dropdown.Toggle>

                <Dropdown.Menu>
                <Dropdown.Item href="/dashboard/settings"><AiOutlineSetting size={20}/> Settings</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={()=>logout()}><AiOutlineLogout size={20}/> Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Navbar>
     );
}
 
export default Header;