import React, { useEffect, useState, useRef } from 'react';
import { Form, Button } from 'react-bootstrap';
import { API } from 'Services'
import logo from 'assets/images/logo.png';
import { Ddux } from 'ddux';
import history from '../History';


const Home = ()=>{
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('')

    const signin=async()=>{
        Ddux.update('loading', 1)
        let data = await API.adminSignin(email,password)
        if(data.success)
        {
            Ddux.update('admin',data.data)
            localStorage.setItem('authData', JSON.stringify({email:email, password:password}));
            localStorage.setItem('admin', JSON.stringify(data.data));
            history.push("/dashboard");
            console.log(data.data)
            Ddux.update('loading', 0)
        }
        else {
            alert(data.error)
            Ddux.update('loading', 0)
        }
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          signin()
        }
    }

    return(
        <div id='screen__home'>

            <div class="bg-animation">
                <div id="stars"></div>
                <div id="stars2"></div>
                <div id="stars3"></div>
                <div id="stars4"></div>
            </div>

            <div className='container'>
                <div className='logo'>
                    <img src={logo} alt="logo" />
                </div>
                <Form>
                    <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" value={email} onKeyDown={handleKeyDown} onChange={(e)=>setEmail(e.target.value)} />
                    </Form.Group>
                    
                    <Form.Group controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" value={password} onKeyDown={handleKeyDown} onChange={(e)=>setPassword(e.target.value)}/>
                    </Form.Group>
                    
                    <center><Button variant="secondary" type="button" onClick={()=>signin()}>
                        Submit
                    </Button></center>
                </Form>
            </div>
        </div>
    )
}

export default Home