import React, { PureComponent } from 'react';
import {
  BarChart, Bar,  XAxis, YAxis, Tooltip, Legend,
} from 'recharts';

// const data = [
//   {
//     name: 'Jan',  Revenue: 2400, amt: 2400,
//   },
// ];

const Barchart = (props) => {
  // static jsfiddleUrl = 'https://jsfiddle.net/alidingling/30763kr7/';
  var data = props.data

    return (
      <BarChart className="ChartDiv"
        width={700}
        height={400}
        data={data}
        stroke="black"
        margin={{
          top: 10, right: 30, left: 60, bottom: 20,
        }}
      >
        <XAxis dataKey="name"/>
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar  dataKey="Revenue" fill="#00cdac"  />
      </BarChart>
    );
}

export default Barchart;