import React, { useEffect, useState, useRef } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { FcHome,FcAssistant,FcBusinessman,FcCurrencyExchange,FcBearish } from "react-icons/fc";
import * as Pages from 'pages'
import { Loader } from 'components'
import history from './History';
import { Ddux, DduxHOC } from 'ddux';
import { API } from 'Services'


const initialState = {
  loading: 0,
  isNavExpanded: false,
  admin: undefined
}
Ddux.initDdux(initialState)

console.log(Ddux.get('loading'))

const Main = ()=>{
    useEffect(()=>{
        //Component did mount/unmount
        async function autosignin() {
          Ddux.update('loading',1)
          if(localStorage.getItem('authData')){
          const {email, password} = await JSON.parse(localStorage.getItem('authData'));
          let data = await API.adminSignin(email, password)
            if(data.success){
              Ddux.update('loading',0)
              Ddux.update('admin',data.data)
              localStorage.setItem('admin', JSON.stringify(data.data));
              history.push(history.location.pathname);
            }
            else{
              localStorage.removeItem('admin')
              localStorage.removeItem('authData')
              history.push("/");
            }
          }
          else Ddux.update('loading',0)
        }
        autosignin();
    },[])
 
    const routes = {
      admin : [
        {name: 'Dashboard', path: '/dashboard/home', component: Pages.DashboardHome, icon: <FcHome /> },
        {name: 'Manage Admins', path: '/dashboard/admins', component: Pages.ManageAdmins, icon: <FcAssistant />},
        {name: 'Manage Users', path: '/dashboard/users', component: Pages.ManageUsers, icon: <FcBusinessman />},
        // {name: 'Manage Wallet', path: '/dashboard/managewallet', component: Pages.ManageWallet, icon: <FcCurrencyExchange /> },
        {name: 'Manage Discounts', path: '/dashboard/discount', component: Pages.ManageDiscount, icon: <FcBearish />}
      ]
    }
    const loader = (Ddux.get('loading')===1)?<Loader />:null
    return(
        <Router history={history}>
        <div className="App">
          {loader}
          {/* Add React Toastify here */}
          <Switch>
            <Route exact path="/">
              <Pages.Home />
            </Route>
            <Route path="/dashboard">
              <Pages.Dashboard routes={routes.admin}>
                  <Switch>
                    {
                      routes.admin.map((item,index)=><Route key={index} path={item.path} exact component={item.component} />)
                    }
                    <Route exact path="/dashboard/settings">
                      <Pages.Settings />
                    </Route>
                    <Route>
                      <Redirect to="/dashboard/home" />
                    </Route>
                  </Switch>
              </Pages.Dashboard>
            </Route>
            <Route>
              <h1>404 Not Found</h1>
            </Route>
          </Switch>
        </div>
      </Router>
    )
}

const main = DduxHOC(Main)
export {  main as Main }