import React, { useState, useEffect } from "react";
import {Table, Pagination} from 'react-bootstrap';
// components
import { AiTwotoneDelete } from "react-icons/ai";
import { FaEdit } from 'react-icons/fa';
import { Ddux } from 'ddux';
import { API } from 'Services'
import { Button, Modal} from 'react-bootstrap'


const AdminTable = (props) => {
  let pagesize = 5
  let adminList = props.adminList
  const [isDelShowing, setIsDelShowing] = useState(false)
  const [selectedItem, setSelectedItem] = useState(false)
  const [currentpage, setCurrentpage] = useState(1)

  useEffect(() => {
    setpage(1);
  }, [adminList])

  const setpage=(pagenum)=>{
    setCurrentpage(pagenum)
  }

  const pagination_component=()=>{
    const pagecount = Math.ceil((adminList.length?adminList.length:0)/pagesize);
    if(pagecount)
    return(
      <Pagination className="float-right mt-3">
              <Pagination.First onClick={()=>setpage(1)}/>
              <Pagination.Prev disabled={currentpage===1} onClick={()=>setpage(currentpage-1)}/>
              {currentpage>=3 && <Pagination.Item onClick={()=>setpage(1)}>{1}</Pagination.Item>}
              {currentpage>3 && <Pagination.Ellipsis />}
              {currentpage!==1 && <Pagination.Item onClick={()=>setpage(currentpage-1)}>{currentpage-1}</Pagination.Item>}
              <Pagination.Item active>{currentpage}</Pagination.Item>
              {currentpage!==pagecount && <Pagination.Item onClick={()=>setpage(currentpage+1)}>{currentpage+1}</Pagination.Item>}
              {currentpage<pagecount-2 && <Pagination.Ellipsis />}
              {currentpage<=pagecount-2 && <Pagination.Item onClick={()=>setpage(pagecount)}>{pagecount}</Pagination.Item>}
              <Pagination.Next disabled={currentpage===pagecount} onClick={()=>setpage(currentpage+1)}/>
              <Pagination.Last onClick={()=>setpage(pagecount)}/>
      </Pagination>
    );
  }

  return (
    <div>
      <Table responsive striped hover style={{fontSize: 14}}>
        <thead style={{color:"#00cdac", borderTop: 'hidden'}}>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Email</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {adminList && adminList.map((item,i) => {
            if(i>=(currentpage-1)*pagesize && i<(currentpage*pagesize))
            return(
              <tr key={i}>
                <td style={{color:"white"}}>{i+1}</td>
                <td style={{color:"white"}}>{item.name}</td>
                <td style={{color:"white"}}>{item.email}</td>
                <td style={{width: '10%'}}>
                  <div className="action-btn" onClick={() => {setIsDelShowing(true);setSelectedItem(item._id)}}>
                    <AiTwotoneDelete size={25} color="#c31432" style={{cursor:"pointer"}}/>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
        <DeleteAdminAlert isDelShowing={isDelShowing} setIsDelShowing={setIsDelShowing} loadAdminList={props.loadAdminList} id={selectedItem}/>
      </Table>
      {
        adminList && adminList.length===0 && <center>No Data Found</center>
      }
      {pagination_component()}
    </div>
  );
}
export default AdminTable

const DeleteAdminAlert = ({isDelShowing, setIsDelShowing, loadAdminList, id})=>{

  const deleteAdmin=async()=>{
    console.log(id)
    Ddux.update('loading', 1)
    let data = await API.deleteAdmin(id)
    if(data.success)
    {
        loadAdminList()
        console.log(data.data)
        Ddux.update('loading', 0)
    }
    else {
        alert(data.error)
        Ddux.update('loading', 0)
        // ShowToast({message: 'System Error!Refresh The Page!',type: 'error'});
    }
    setIsDelShowing(false)
  }

  return (
    <>
      <Modal
        show={isDelShowing}
        onHide={()=>{setIsDelShowing(false)}}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Admin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="form">
            <p>Are you sure you want to do this?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={()=>{setIsDelShowing(false)}} >
              NO
            </Button>
            <Button variant="primary" onClick={()=>deleteAdmin()}>Yes</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }