import React from 'react';
import 'assets/css/index.scss';
import { Main } from 'pages/Main'

function App() {
  return (
    <Main />
  );
}

export default App;