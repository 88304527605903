let Global = {
	apiUrl: 'http://45.35.62.58:3002',
	siteUrl: 'http://admin.inrangeit.com',
}

if(process.env.NODE_ENV==='development'){
	Global.apiUrl = 'http://45.35.62.58:3002'
	Global.siteUrl = 'http://admin.inrangeit.com'
}

export default Global;
