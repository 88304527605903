import React, { useEffect, useState, useRef } from 'react';
import { InputGroup, Button, FormControl } from 'react-bootstrap';
import { API } from 'Services'
import Global from 'store/Global';
import { Ddux } from 'ddux';
import { FcBusinessman } from "react-icons/fc";

const Settings = () => {

    const [userData, setUserData] = useState(JSON.parse(localStorage.getItem('admin')))
    const [name, setName] = useState(userData.name)
    const [email, setEmail] = useState(userData.email)
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const handleSubmit = async() => {
        Ddux.update('loading', 1)
        if(password != confirmPassword)
            alert('Password & Confirm Password Must Be Same!')
        let data = await API.updateProfile(name,email,confirmPassword)
        console.log(data)
        if(data.success)
        {
            Ddux.update('admin',data.data)
            localStorage.setItem('admin', JSON.stringify(data.data));
            Ddux.update('loading', 0)
        }
        else alert(data.error);
        Ddux.update('loading', 0)
    }

    return (
        <div id='screen__settings'>
            <h4 className='tab-title'>Settings</h4>
            <div className='ui-container profile-container'>

                <div className='dp'><FcBusinessman style={{ alignSelf: 'center' }} /></div>
                <InputGroup className="mb-3" style={{ width: 300, margin: 'auto', marginTop: 10 }}>
                    <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">Name</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        placeholder="Name"
                        aria-label="Username"
                        value={name}
                        aria-describedby="basic-addon1"
                        onChange={e=>setName(e.target.value)}
                    />
                </InputGroup>
                <InputGroup className="mb-3" style={{ width: 300, margin: 'auto', marginTop: 10 }}>
                    <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">Email</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        placeholder="Email"
                        aria-label="Username"
                        value={email}
                        aria-describedby="basic-addon1"
                        onChange={e=>setEmail(e.target.value)}
                        disabled
                    />
                </InputGroup>

                <InputGroup className="mb-3" style={{ width: 300, margin: 'auto', marginTop: 10 }}>
                    <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">Password</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        placeholder="Password"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={password}
                        onChange={e=>setPassword(e.target.value)}
                        type="password"
                    />
                </InputGroup>
                <InputGroup className="mb-3" style={{ width: 300, margin: 'auto', marginTop: 10 }}>
                    <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">Confirm</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        placeholder="Confirm Password"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                        value={confirmPassword}
                        onChange={e=>setConfirmPassword(e.target.value)}
                        type="password"
                    />
                </InputGroup>
                <center><Button variant="secondary" type="button" onClick={()=>handleSubmit()}>Update Profile</Button></center>
                <br /><br />
            </div>
        </div>
    )
}

export default Settings