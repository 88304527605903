import React, { useState, useEffect } from "react";
import DiscountTable from './DiscountTable';
import { BsFileEarmarkPlus } from 'react-icons/bs'
import { Button, InputGroup, FormControl, Modal, Form, Col } from 'react-bootstrap'
import { AiOutlineUser, AiOutlineSearch } from "react-icons/ai";
import { GiCash } from "react-icons/gi";
import { MdDateRange } from "react-icons/md";
import { BiCut } from "react-icons/bi";
import { API } from 'Services'
import { Ddux } from 'ddux';

const ManageDiscount = (props) => {
  const [isShowing, setIsShowing] = useState(false)
  const [couponList, setCouponList] = useState([])
  const [showList, setShowList] = useState([])

  useEffect(() => {
    loadCouponList()
  },[]);

  const loadCouponList=async()=>{
    Ddux.update('loading', 1)
    let data = await API.listAllCoupon()
    if(data.success)
    {
        setCouponList(data.data)
        setShowList(data.data)
        Ddux.update('loading', 0)
    }
    else {
        alert(data.error)
        Ddux.update('loading', 0)
        // ShowToast({message: 'System Error!Refresh The Page!',type: 'error'});
    }
  }

  const searching=(searchdata)=>{
    setShowList(couponList.filter(function(item){
        return (item.code.toLowerCase().search(searchdata.toLowerCase()) !== -1) || (item.amount.toString().toLowerCase().search(searchdata.toLowerCase()) !== -1);
    }));
  }

  return (
    <div id="screen__manageDiscount" className="main-container">
      <h4 className='tab-title'>Manage Discount</h4>
      <div className="table-header">
        <div className="search-bar">
          <div className="form-inline mr-auto">
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Search"
                aria-label="Search"
                aria-describedby="Search"
                onChange={(e)=>searching(e.target.value)}
              />
              <InputGroup.Append>
                <Button variant="outline-secondary"><AiOutlineSearch size={25} color="#00cdac" style={{ cursor: "pointer" }} /></Button>
              </InputGroup.Append>
            </InputGroup>
          </div>
        </div>
        <div className="header-right-button">
          <Button variant="outline-secondary" onClick={()=>setIsShowing(true)} ><BsFileEarmarkPlus size={25} color="#00cdac" style={{ cursor: "pointer" }} /> Add Coupon</Button>
        </div>
      </div>
      <div className='ui-container margin-10'>
        <h6 className="widget-title padding-10">Manage Discount</h6>
        <DiscountTable couponList={showList} loadCouponList={loadCouponList}/>
      </div>
      <AddCouponCode isShowing={isShowing} setIsShowing={setIsShowing} loadCouponList={loadCouponList} />
    </div>
  );
}
export { ManageDiscount }



const AddCouponCode = ({ isShowing, setIsShowing, loadCouponList }) => {

  const [amount, setAmount] = useState('')
  const [code, setCode] = useState('')
  const [expiryDate, setExpiryDate] = useState('')
  const [isPercent, setisPercent] = useState(false)

  const createCoupon=async()=>{
    Ddux.update('loading', 1)
    let data = await API.createCoupon(code, amount, isPercent, expiryDate)
    if(data.success)
    {
        loadCouponList()
        console.log(data.data)
        Ddux.update('loading', 0)
    }
    else {
        alert(data.error)
        Ddux.update('loading', 0)
        // ShowToast({message: 'System Error!Refresh The Page!',type: 'error'});
    }
    setIsShowing(false)
  }

  return (
    <>
      <Modal
        show={isShowing}
        onHide={()=>setIsShowing(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Coupon</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form">

          <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text><BiCut /></InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                type="text"
                placeholder="Enter Code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text><GiCash /></InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                type="text"
                placeholder="Enter Amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
              <Col xs="auto" className="my-1">
                <Form.Check
                  type="checkbox"
                  id="customControlAutosizing"
                  label="Percentage(%)"
                  custom
                  checked={isPercent}
                  onClick={(e) => setisPercent(!isPercent)}
                />
              </Col>
            </InputGroup>
            <InputGroup controlId="formBasicExpiry" className="create-group" style={{width: '50%'}}>
              <InputGroup.Prepend>
                <InputGroup.Text><MdDateRange /></InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control type="date" value={expiryDate} onChange={(e) => setExpiryDate(e.target.value)} />
            </InputGroup>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setIsShowing(false)} >
            Close
          </Button>
          <Button variant="primary" onClick={()=>createCoupon()}>Submit</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

