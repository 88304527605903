import React, { useState, useEffect } from "react";
import { AiOutlineArrowUp, AiOutlineArrowDown } from 'react-icons/ai'
import { Ddux } from 'ddux';
import { API } from 'Services'
import { Barchart, Piechart } from 'components'
import {Table} from 'react-bootstrap';
import history from '../History';

const DashboardHome = () => {
  const [statData, setStatData] = useState({})

    useEffect(() => {
      loadStat()
      },[]);
    
    const loadStat=async()=>{
        Ddux.update('loading', 1)
        let data = await API.getstats()
        if(data.success)
        {
            await setStatData(data.data)
            // console.log(data.data,'test')
            Ddux.update('loading', 0)
        }
        else {
            alert(data.error)
            Ddux.update('loading', 0)
            // ShowToast({message: 'System Error!Refresh The Page!',type: 'error'});
        }
    }

  const widget = (
    <div className='grid-widget'>
      <div className='grid-container padding-10'>
        <GridWidget title='New Users' value={statData.noofuser} percent={statData.userchangepercent} />
      </div>
      <div className='grid-container padding-10'>
        <GridWidget title='Transaction Amount' value={statData.transactionamount && statData.transactionamount[0].amount} percent={statData.transactionchangepercent} />
      </div>
      <div className='grid-container padding-10'>
        <GridWidget title='Completed Tasks' value={statData.noofcompletedtask} />
      </div>
      <div className='grid-container padding-10'>
        <GridWidget title='Active Tasks' value={statData.noofactivetask} />
      </div>
    </div>
  )
  return (
    <div id='screen__dashboardHome' className='overflow-hidden'> 
      <h4 className='tab-title'>Welcome to Dashboard</h4>
      
      {widget}

      <div className='grid-widget'>
        <div className='grid-container padding-10'>
          <div className='ui-container overflow-x-sm'>
            <h6 className="widget-title padding-10">Monthly Revenue {new Date().getFullYear()}</h6>
            <Barchart data={statData.revenueList}/>
          </div>
        </div>
        <div className='grid-container padding-10'>
          <div className='ui-container full-height flex-center'>
            <h6 className="widget-title padding-10">User Ratio</h6>
            <ul className='pieChartList'>
              <li ><div className='dot-non-paid'></div> Non Paid </li>
              <li><div className='dot-paid'></div> Paid </li>
            </ul>
            <Piechart data={[{name: 'nonpaid',value: statData.noofuser-statData.paiduser},{name: 'paid',value: statData.paiduser}]} />
          </div>
        </div>
      </div>

      <div className='ui-container margin-10'>
        <h6 className='widget-title padding-10'>Latest Tasks</h6>
        <div className='padding-10'><TableWidget data={statData.latesttask} /></div>
      </div>
    </div>
  );
}

const GridWidget = ({ title = '', value = 0, percent = null }) => {
  return (
    <div className='ui-container padding-15 full-height' >
      <div className='widget-title'>{title}</div>
      <div className='widget-value'>{value}</div>
      {
        (percent && percent > 0) ?
          <div className='widget-percentage-increment'>+{percent}%<AiOutlineArrowUp size={25} /></div> :
          (percent && percent <= 0) ? <div className='widget-percentage-decrement'>{percent}%<AiOutlineArrowDown size={25} /></div> : null
      }
    </div>
  )
}

const TableWidget = (data) => {
  const tabledata = data.data
  return (
    <Table striped hover responsive style={{fontSize: 14}}>
      <thead style={{color:"#00cdac", borderTop: 'hidden'}}>
        <tr>
          <th>#</th>
          <th>Title</th>
          <th>Name</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody >
        {tabledata && tabledata.map((item,i) => (
            <tr key={i}>
              <td style={{color:"white"}}>{i+1}</td>
              <td style={{color:"white"}}>{item.title}</td>
              <td style={{color:"white"}}>{item.name}</td>
              <td style={{color:"white"}}>{item.status}</td>
            </tr>
          ))}
      </tbody>
    </Table>
  )
}

export { DashboardHome }
