/*API SERVICES*/
import Global from 'store/Global';
import axios from 'axios';
import { Ddux } from 'ddux';
const API = axios.create({ baseURL: Global.apiUrl });

// 
const getstats = async () => {
	let response = null
    let admin = Ddux.get('admin')

	await API.get('/admins/stats',
	{
		headers: {'Authorization': 'Bearer: ' + 'admin.token'},
		'Content-Type': 'multipart/form-data'
	})
	.then(function (res) {
		response = res
	})
	.catch(function (error) {
		response = error.response
	})
	return handleResponse(response);
}

// Admin -----------

const adminSignin = async (email,password) => {
	let response = null
    let admin = Ddux.get('admin')

	await API.post('/admins/login',
	{
		email: email,
		password: password,
	},{
		headers: {'Authorization': 'Bearer: ' + 'admin.token'},
		'Content-Type': 'multipart/form-data'
	})
	.then(function (res) {
		response = res
	})
	.catch(function (error) {
		response = error.response
	})
	return handleResponse(response);
}

const updateProfile = async (name,email,password) => {
	let response = null
    let admin = Ddux.get('admin')

	await API.post('/admins/update-profile',
	{
		name: name,
		email: email,
		password: password,
	},{
		headers: {'Authorization': 'Bearer: ' + 'admin.token'},
		'Content-Type': 'multipart/form-data'
	})
	.then(function (res) {
		response = res
	})
	.catch(function (error) {
		response = error.response
	})
	return handleResponse(response);
}

const addAdmin = async (name,email,password,type) => {
	let response = null
    let admin = Ddux.get('admin')

	await API.post('/admins/signup',
	{
		name: name,
		email: email,
		password: password,
		type: type
	},{
		headers: {'Authorization': 'Bearer: ' + 'admin.token'},
		'Content-Type': 'multipart/form-data'
	})
	.then(function (res) {
		response = res
	})
	.catch(function (error) {
		response = error.response
	})
	return handleResponse(response);
}

const listAllAdmin = async () => {
	let response = null
    let admin = Ddux.get('admin')

	await API.get('/admins/getalladmin',
	{
		headers: {'Authorization': 'Bearer: ' + 'admin.token'},
		'Content-Type': 'multipart/form-data'
	})
	.then(function (res) {
		response = res
	})
	.catch(function (error) {
		response = error.response
	})
	return handleResponse(response);
}

const deleteAdmin = async (id) => {
	let response = null
    let admin = Ddux.get('admin')
	const data = {id: id}
	await API.delete('/admins/deleteadmin',
	{
		headers: {'Authorization': 'Bearer: ' + 'admin.token'},
		data
	})
	.then(function (res) {
		response = res
	})
	.catch(function (error) {
		response = error.response
	})
	return handleResponse(response);
}

// User -----------

const listAllUser = async () => {
	let response = null
    let admin = Ddux.get('admin')

	await API.get('/admins/getalluser',
	{
		headers: {'Authorization': 'Bearer: ' + 'admin.token'},
		'Content-Type': 'multipart/form-data'
	})
	.then(function (res) {
		response = res
	})
	.catch(function (error) {
		response = error.response
	})
	return handleResponse(response);
}

const changeUserStatus = async (id,status) => {
	let response = null
    let admin = Ddux.get('admin')
	const data = {
		id: id,
		status: status,
    }
	await API.post('/admins/updateuserstatus',
	data,{
		headers: {'Authorization': 'Bearer: ' + 'admin.token'},
		'Content-Type': 'multipart/form-data'
	})
	.then(function (res) {
		response = res
	})
	.catch(function (error) {
		response = error.response
	})
	return handleResponse(response);
}

// Coupon ---------------
const createCoupon = async (code, amount, isPercent, expiry) => {
	let response = null
	let admin = Ddux.get('admin')
	const data = {
		code: code,
		amount: parseInt(amount),
		isPercent: isPercent,
		expiry: expiry
    }
    
	await API.post('/admins/createcoupon',
	data,{
		headers: {'Authorization': 'Bearer: ' + 'admin.token'},
		'Content-Type': 'application/json'
	})
	.then(function (res) {
		response = res
	})
	.catch(function (error) {
		response = error.response
	}) 
	return handleResponse(response);
}

const editCoupon = async (id, code, expiry, amount, isPercent) => {
	let response = null
	let admin = Ddux.get('admin')
	const data = {
        id: id,
		code: code,
		expiry: expiry,
		amount: amount,
		isPercent: isPercent,
    }
    
	await API.post('/admins/editcoupon',
	data,{
		headers: {'Authorization': 'Bearer: ' + 'admin.token'},
		'Content-Type': 'application/json'
	})
	.then(function (res) {
		response = res
	})
	.catch(function (error) {
		response = error.response
	}) 
	return handleResponse(response);
}

const deleteCoupon = async (id) => {
	let response = null
	let admin = Ddux.get('admin')
	const data = {id: id}
	await API.delete('/admins/deletecoupon',
	{
		headers: {'Authorization': 'Bearer: ' + 'admin.token'},
		data
	})
	.then(function (res) {
		response = res
	})
	.catch(function (error) {
		response = error.response
	}) 
	return handleResponse(response);
}

const listAllCoupon = async () => {
	let response = null
    let admin = Ddux.get('admin')

	await API.get('/admins/getallcoupon',
	{
		headers: {'Authorization': 'Bearer: ' + 'admin.token'},
		'Content-Type': 'multipart/form-data'
	})
	.then(function (res) {
		response = res
	})
	.catch(function (error) {
		response = error.response
	})
	return handleResponse(response);
}

/* Utility Methods */

const handleResponse = response => {
	//console.log(response)
	if(response === null || response.status === undefined){
		return {
			success: false,
			error: response
		}
	}
	if(response.status === 202){
		return {
			success: false,
			error: response.data.error
		}
	}
	// temp changes 401 to 4001
	else if(response.status === 4001){
		window.location = "/";
		return {
			success: false,
			error: JSON.stringify(response.data.error)
		}
	}
	else if(response.status !== 200){
		return {
			success: false,
			error: JSON.stringify(response.data.error)
		}
	}
	return {
		success: true,
		data: response.data.data
	}
}

const dataURLtoFile = (dataurl, filename) => {
	const arr = dataurl.split(',')
	const mime = arr[0].match(/:(.*?);/)[1]
	const bstr = atob(arr[1])
	let n = bstr.length
	const u8arr = new Uint8Array(n)
	while (n) {
	  u8arr[n - 1] = bstr.charCodeAt(n - 1)
	  n -= 1 // to make eslint happy
	}

	return new File([u8arr], filename, { type: mime })
}

const isDataURL = (s)=>{
	let regex = /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;
    return !!s.match(regex);
}

const api = {
  getstats: getstats,
  adminSignin: adminSignin,
  updateProfile: updateProfile,
  addAdmin: addAdmin,
  listAllAdmin: listAllAdmin,
  deleteAdmin: deleteAdmin,
  createCoupon: createCoupon,
  editCoupon: editCoupon,
  deleteCoupon: deleteCoupon,
  listAllCoupon: listAllCoupon,
  listAllUser: listAllUser,
  changeUserStatus: changeUserStatus
}

export {api as API}
