import React, { useEffect, useState, useRef } from 'react';
import Header from './Header'
import SideNavigation from './SideNav'
import { Breadcum } from 'components'

const Dashboard = props =>{
    const [expanded, setExpanded] = useState(false)
    return(
        <div id="screen__dashboard">
                <Header toggle={()=>setExpanded(!expanded)} expanded={expanded} />
                <div className="flex-wrapper">
                    <SideNavigation routes={props.routes} toggle={()=>setExpanded(!expanded)} expanded={expanded} />
                    <div className={`body ${(expanded)?'body-expanded':''}`}>
                    
                        {props.children}
                    </div>    
                </div>
            </div>
    )
}

export default Dashboard