import React, { useState, useEffect } from "react";
import {Table, Pagination} from 'react-bootstrap';
// components
import { Ddux } from 'ddux';
import { API } from 'Services'
import { FaEdit } from 'react-icons/fa';
import { AiFillDelete } from 'react-icons/ai';
import { Button, Modal} from 'react-bootstrap';


const DiscountTable = (props) => {
  let pagesize = 5
  let couponList = props.couponList
  const [selectedItem, setSelectedItem] = useState(false)
  const [isShowing, setIsShowing] = useState(false)
  const [currentpage, setCurrentpage] = useState(1)

  const closeModal = () => setIsShowing(false);

  useEffect(() => {
    setpage(1);
  }, [couponList])

  const setpage=(pagenum)=>{
    setCurrentpage(pagenum)
  }

  const dateconvert=(iDate)=>{
    var a = new Date(iDate);
    return ("0"+a.getDate()).slice(-2) + "-" + ("0"+(a.getMonth()+1)).slice(-2)  + "-" + a.getFullYear();
  }

  const pagination_component=()=>{
    const pagecount = Math.ceil((couponList.length?couponList.length:0)/pagesize);
    if(pagecount)
    return(
      <Pagination className="float-right mt-3">
              <Pagination.First onClick={()=>setpage(1)}/>
              <Pagination.Prev disabled={currentpage===1} onClick={()=>setpage(currentpage-1)}/>
              {currentpage>=3 && <Pagination.Item onClick={()=>setpage(1)}>{1}</Pagination.Item>}
              {currentpage>3 && <Pagination.Ellipsis />}
              {currentpage!==1 && <Pagination.Item onClick={()=>setpage(currentpage-1)}>{currentpage-1}</Pagination.Item>}
              <Pagination.Item active>{currentpage}</Pagination.Item>
              {currentpage!==pagecount && <Pagination.Item onClick={()=>setpage(currentpage+1)}>{currentpage+1}</Pagination.Item>}
              {currentpage<pagecount-2 && <Pagination.Ellipsis />}
              {currentpage<=pagecount-2 && <Pagination.Item onClick={()=>setpage(pagecount)}>{pagecount}</Pagination.Item>}
              <Pagination.Next disabled={currentpage===pagecount} onClick={()=>setpage(currentpage+1)}/>
              <Pagination.Last onClick={()=>setpage(pagecount)}/>
      </Pagination>
    );
  }

  return (
    <div>
      <Table responsive striped hover style={{fontSize:14,textAlign: 'center'}}>
        <thead style={{color:"#00cdac", borderTop: 'hidden'}}>
          <tr>
            <th>#</th>
            <th>Code</th>
            <th>Discount</th>
            <th>Create Date</th>
            <th>Expiry Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody >
          {couponList && couponList.map((item,i) => {
            if(i>=(currentpage-1)*pagesize && i<(currentpage*pagesize))
            return(
              <tr key={i}>
                <td style={{color:"white"}}>{i+1}</td>
                <td style={{color:"white"}}>{item.code}</td>
                <td style={{color:"white"}}>{!item.isPercent && '$'}{item.amount}{item.isPercent && '%'}</td>
                <td style={{color:"white"}}>{dateconvert(item.createdAt)}</td>
                <td style={{color:"white"}}>{dateconvert(item.expiry)}</td>
                <td style={{width: '10%'}}>
                  <div className="action-btn" onClick={()=>{setSelectedItem(item._id);setIsShowing(true)}}>
                      <AiFillDelete size={25} color="#c31432" style={{cursor:"pointer"}} onClick={()=>setIsShowing(true)}/>
                  </div>
                </td>
              </tr>
          )})}
        </tbody>
      </Table>
      {
        couponList && couponList.length===0 && <center>No Data Found</center>
      }
      {pagination_component()}
      <ExpireCouponAlert isShowing={isShowing} loadCouponList={props.loadCouponList} closeModal={closeModal} id={selectedItem}/>
    </div>
  );
}
export default DiscountTable


const ExpireCouponAlert = ({isShowing, closeModal,loadCouponList, id})=>{

  const deleteCoupon=async()=>{
    console.log(id)
    Ddux.update('loading', 1)
    let data = await API.deleteCoupon(id)
    if(data.success)
    {
        loadCouponList()
        console.log(data.data)
        Ddux.update('loading', 0)
        closeModal()
    }
    else {
        alert(data.error)
        Ddux.update('loading', 0)
        // ShowToast({message: 'System Error!Refresh The Page!',type: 'error'});
    }
  }

  return (
    <>
      <Modal
        show={isShowing}
        onHide={closeModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Coupon</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="form">
            <p>Are you sure you want to do this?</p>
        </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={closeModal} >
              NO
            </Button>
            <Button variant="primary" onClick={()=>deleteCoupon()}>Yes</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
  }