import React, { useState, useEffect } from "react";
import { InputGroup, FormControl, Button, Modal } from 'react-bootstrap';
import { AiOutlineSearch, AiOutlineMail, AiOutlineUser, AiOutlineUserAdd, AiOutlineSecurityScan } from "react-icons/ai";
import { API } from 'Services'
import { Ddux } from 'ddux';
import AdminTable from './AdminTable';


const ManageAdmins = () => {
  const [isShowing, setIsShowing] = useState(false)
  const [adminList, setAdminList] = useState([])
  const [showList, setShowList] = useState([])

    useEffect(() => {
        loadAdminList()
      },[]);
    
    const loadAdminList=async()=>{
        Ddux.update('loading', 1)
        let data = await API.listAllAdmin()
        if(data.success)
        {
            setAdminList(data.data)
            setShowList(data.data)
            Ddux.update('loading', 0)
        }
        else {
            alert(data.error)
            Ddux.update('loading', 0)
            // ShowToast({message: 'System Error!Refresh The Page!',type: 'error'});
        }
    }

    const searching=(searchdata)=>{
      setShowList(adminList.filter(function(item){
          return (item.name.toLowerCase().search(searchdata.toLowerCase()) !== -1) || (item.email.toString().toLowerCase().search(searchdata.toLowerCase()) !== -1);
      }));
    }

  return (
    <div id="screen__manageAdmin" className="main-container">
      <h4 className='tab-title'>Manage Admins</h4>

      <div className="table-header">
        <div className="search-bar">
          <div className="form-inline mr-auto">
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Search"
                aria-label="Search"
                aria-describedby="Search"
                onChange={(e)=>searching(e.target.value)}
              />
              <InputGroup.Append>
                <Button variant="outline-secondary"><AiOutlineSearch size={25} color="#00cdac" style={{ cursor: "pointer" }} /></Button>
              </InputGroup.Append>
            </InputGroup>
          </div>
        </div>
        <div className="header-right-button">
          <Button variant="outline-secondary" onClick={()=>setIsShowing(true)} ><AiOutlineUserAdd size={25} color="#00cdac" style={{ cursor: "pointer" }} /> Add Admin</Button>
        </div>
      </div>
      <div className='ui-container margin-10'>
        <h6 className="widget-title padding-10">Manage Admin</h6>
        <AdminTable adminList={showList} loadAdminList={loadAdminList}/>
      </div>
      <AddAdminModal isShowing={isShowing} setIsShowing={setIsShowing} loadAdminList={loadAdminList}/>
    </div>
  );
}
export { ManageAdmins }


const AddAdminModal = ({ isShowing, setIsShowing, loadAdminList }) => {

  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [type, setType] = useState('admin')

  const addAdmin=async()=>{
    Ddux.update('loading', 1)
    let data = await API.addAdmin(name,email,password,type)
    if(data.success)
    {
        loadAdminList()
        console.log(data.data)
        Ddux.update('loading', 0)
        setIsShowing(false)
    }
    else {
        alert(data.error)
        Ddux.update('loading', 0)
        // ShowToast({message: 'System Error!Refresh The Page!',type: 'error'});
    }
  }

  return (
    <>
      <Modal
        show={isShowing}
        onHide={()=>setIsShowing(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Admin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form">
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="email-addon1"><AiOutlineUser /></InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                type="text"
                placeholder="Enter Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="email-addon2"><AiOutlineMail /></InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                type="email"
                placeholder="Enter Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="email-addon3"><AiOutlineSecurityScan /></InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                type="password"
                placeholder="Enter Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text id="email-addon3"> Type </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl as="select">
                <option onClick={()=>setType('admin')}>Admin</option>
                <option onClick={()=>setType('analyst')}>Analyst</option>
              </FormControl>
            </InputGroup>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>setIsShowing(false)} >
            Close
            </Button>
          <Button variant="primary" onClick={()=>addAdmin()}>Submit</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}